import React, { useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import FormError from "./../FormError";

import { requestDemo } from "../../../services/ApiService";
import close from "../../../assets/images/adwords/close.svg";
import nudge from "../../../assets/images/adwords/nudge.svg";
import { ReactComponent as Chevron } from "../../../assets/images/navigation/right.svg";
import CustomerNote from "../../Adwords/CustomerNote";
import Flag from "../../../assets/images/indian-flag.svg";
import Flag2 from "../../../assets/images/bangladesh-flag.svg";
import LabelWithTick from "./helper";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../generic";

const FORM_INITIAL_STATE = {
  name: "",
  email: "",
  contact: "",
  fabricCategory: "",
  fabricType: "",
  requiredQuantity: "",
  category: "",
};

const FABRIC_CATEGORY = ["Cotton", "Rayon", "Blends", "Others"];
const FABRIC_CATEGORY_INTERNATIONAL = [
  "Cotton",
  "Linen",
  "Dobbies",
  "Denim",
  "Others",
];
const FABRIC_CATEGORY_FOR_DENIM = ["100% Cotton", "Blends"];
const FABRIC_TYPES = ["Greige", "Yarn dyed", "Dyed", "Printed"];
const REQUIRED_QUANTITY = ["Upto 15,000 m", "Above 15,000 m"];
const REQUIRED_QUANTITY_FOR_DENIM = ["Upto 3,000 m", "Above 3,000 m"];
const REQUIRED_QUANTITY_FOR_HOISERY = ["Upto 1000 kg", "Above 1000 kg"];
const REQUIRED_QUANTITY_INTERNATIONAL = ["Upto 50,000 yd", "Above 50,000 yd"];
const REQUIRED_QUANTITY_LINEN = [
  "Upto 2000 yd",
  "2000 to 5000 yd",
  "Above 5000 yd",
];
export const INTERNATIONAL = "Bangladesh";

const ValidationSchema = (location) =>
  Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z](?!.*\.\.)[a-zA-Z .]*[a-zA-Z]$/, "Enter a valid name")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("This field is required"),
    email: Yup.string().email("Enter a valid Email ID"),
    contact: Yup.string()
      .matches(
        location === INTERNATIONAL ? /^01[3-9]\d{8}$/ : /^[1-9]{1}[0-9]{9}$/,
        "Enter a valid contact number"
      )
      .required("This field is required"),
    fabricCategory: Yup.string(),
    fabricType: Yup.string(),
    requiredQuantity: Yup.string(),
    businessType: Yup.string(),
    category: Yup.string(),
  });

const RequestForm = ({
  setSubmitSuccess,
  containerClass = "",
  submitText = "Request a call back",
  closedText = "Close",
  onClick = () => {},
  closed = false,
}) => {
  const { search } = useLocation();
  const location = new URLSearchParams(search).get("location");
  const requiredCategory = new URLSearchParams(search).get("category");
  const categoryReq = new URLSearchParams(search)
    .get("requirement")
    ?.toLowerCase();
  const requiremtCheck = ["greige", "denim", "hosiery"].includes(categoryReq);
  const requirement = requiremtCheck ? categoryReq : "greige";
  const isLinenOrDenim =
    requiredCategory === "Linen" || requiredCategory === "Denim";
  const handleSubmit = ({
    name,
    email,
    contact,
    fabricCategory,
    fabricType,
    requiredQuantity,
    category,
  }) => {
    let payload = { name, formType: "adwords" };
    if (location === INTERNATIONAL) {
      payload["contact"] = `+880 ${contact}`;
      payload["location"] = INTERNATIONAL;
    } else {
      payload["contact"] = `+91 ${contact}`;
    }
    if (email) {
      payload["email"] = email;
    }
    if (fabricCategory) {
      if (fabricCategory === "Others") {
        payload["fabricCategory"] = category;
      } else {
        payload["fabricCategory"] = fabricCategory;
      }
    }
    if (fabricType) {
      payload["fabricType"] = fabricType;
    }
    if (requiredQuantity) {
      payload["requiredQuantity"] = requiredQuantity;
    }
    if (["greige", "denim", "hosiery"].includes(requirement)) {
      payload["fabricType"] = capitalizeFirstLetter(requirement);
    }
    requestDemo({
      ...payload,
    })
      .then(() => {
        setSubmitSuccess(true);

        if (window.gtag_report_conversion) {
          window.gtag_report_conversion();
        }
      })
      .catch((err) => {
        let msg =
          "Error occurred while submitting the request. Please try again.";

        if (typeof err === "string") {
          msg = `Following e${msg.substr(1)}\n${err}`;
        }

        alert(msg);
      });
  };

  const formContentRef = useRef();
  const checkboxContainerRef = useRef();
  const quantityRef = useRef();
  const [controlAlignment, setControlAlignment] = useState(false);
  const isControlRight = controlAlignment === "rightSide";
  const scrollCheckboxContainer = (e) => {
    const val = e.target.getAttribute("data-variant");
    e.preventDefault();
    // Note: To be Refactored
    if (val === "left") {
      setControlAlignment("left");
      checkboxContainerRef.current.classList.add("left");
      checkboxContainerRef.current.classList.remove("right");
    } else {
      setControlAlignment("right");
      checkboxContainerRef.current.classList.add("right");
      checkboxContainerRef.current.classList.remove("left");
    }
  };
  const scrollQuantityContainer = (e) => {
    const val = e.target.getAttribute("data-variant");
    e.preventDefault();
    if (val === "leftSide") {
      setControlAlignment("leftSide");
      quantityRef.current.classList.add("left");
      quantityRef.current.classList.remove("right");
    } else {
      setControlAlignment("rightSide");
      quantityRef.current.classList.add("right");
      quantityRef.current.classList.remove("left");
    }
  };
  if (requiredCategory) {
    FORM_INITIAL_STATE.fabricCategory = requiredCategory;
  }
  return (
    <Formik
      initialValues={FORM_INITIAL_STATE}
      validationSchema={ValidationSchema(location)}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid, dirty, setFieldValue, values }) => (
        <Form className={`adwordsForm ${containerClass} form`}>
          {closed && (
            <div className="nudge">
              <img src={nudge} alt="nudge" style={{ marginTop: "-24px" }} />
            </div>
          )}
          <div className="adwordsFormContainer__header">
            <div className="heading">Share your requirement</div>
            {closed && (
              <img
                src={close}
                alt="close"
                style={{ marginTop: "-24px" }}
                onClick={onClick}
              />
            )}
          </div>
          <div className="formContent" ref={formContentRef}>
            <div>
              <div className="field">
                <label htmlFor="name">
                  <LabelWithTick
                    label="Name"
                    withValidation={true}
                    style={{ color: " #676773" }}
                    isValid={values.name && !errors.name}
                  />
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter name"
                  />
                </label>
                {touched.name && errors.name ? (
                  <FormError error={errors.name} errorClass="field-error" />
                ) : null}
              </div>
              <div className="field-contact field">
                <label htmlFor="contact">
                  <LabelWithTick
                    label="Mobile number"
                    withValidation={true}
                    style={{ color: " #676773" }}
                    isValid={values.contact && !errors.contact}
                  />
                  <Field name="contact">
                    {({ field }) => (
                      <div className="phoneInput">
                        {location === INTERNATIONAL ? (
                          <>
                            <div className="object-fill">
                              <img src={Flag2} alt="flag" />
                            </div>
                            <div className="flag"> +880 </div>
                          </>
                        ) : (
                          <>
                            <div className="object-fill">
                              <img src={Flag} alt="flag" />
                            </div>
                            <div className="flag"> +91 </div>
                          </>
                        )}
                        <input
                          onChange={(value) => setFieldValue(field.name, value)}
                          value={values.contact}
                          type="text"
                          placeholder="Mobile number"
                          id="contact"
                          name="contact"
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                </label>
                {touched.contact && errors.contact ? (
                  <FormError error={errors.contact} errorClass="field-error" />
                ) : null}
              </div>
            </div>
            <div className="field">
              <label>
                {requirement == "denim" ? "Composition" : "Fabric category"}
              </label>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                ref={checkboxContainerRef}
              >
                {(requirement == "denim"
                  ? FABRIC_CATEGORY_FOR_DENIM
                  : FABRIC_CATEGORY
                ).map((type) => (
                  <label key={type} className="radioButton">
                    <Field
                      type="radio"
                      name="fabricCategory"
                      value={type}
                      onChange={(event) => {
                        setFieldValue("fabricCategory", event.target.value);
                      }}
                      checked={values.fabricCategory === type}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
              {requirement != "denim" ? (
                controlAlignment === "right" ? (
                  <button
                    type="button"
                    onClick={scrollCheckboxContainer}
                    data-variant="left"
                    className="controls leftCTA btn"
                  >
                    <Chevron />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={scrollCheckboxContainer}
                    data-variant="right"
                    className="controls rightCTA btn"
                  >
                    <Chevron />
                  </button>
                )
              ) : null}
              {values.fabricCategory === "Others" && (
                <Field
                  type="text"
                  id="category"
                  name="category"
                  placeholder="Enter category"
                />
              )}
            </div>

            {/* <div className="field">
              <label>Requirement</label>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
              >
                {FABRIC_TYPES.map((type) => (
                  <label key={type} className="radioButton">
                    <Field
                      type="radio"
                      name="fabricType"
                      value={type}
                      onChange={(event) => {
                        setFieldValue("fabricType", event.target.value);
                      }}
                      checked={values.fabricType === type}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </div> */}

            <div className="field">
              <label>Quantity required</label>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                ref={quantityRef}
              >
                {(location === INTERNATIONAL
                  ? isLinenOrDenim
                    ? REQUIRED_QUANTITY_LINEN
                    : REQUIRED_QUANTITY_INTERNATIONAL
                  : requirement == "hosiery"
                  ? REQUIRED_QUANTITY_FOR_HOISERY
                  : requirement == "denim"
                  ? REQUIRED_QUANTITY_FOR_DENIM
                  : REQUIRED_QUANTITY
                ).map((type) => (
                  <label key={type} className="radioButton">
                    <Field type="radio" name="requiredQuantity" value={type} />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
              {isLinenOrDenim ? (
                <button
                  type="button"
                  onClick={scrollQuantityContainer}
                  data-variant={isControlRight ? "leftSide" : "rightSide"}
                  className={`controls ${
                    isControlRight ? "leftCTA" : "rightCTA"
                  } btn`}
                >
                  <Chevron />
                </button>
              ) : (
                <div></div>
              )}
            </div>
            <div className="field">
              <label htmlFor="email">
                <span>Work email ID</span>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="name@company.com"
                />
              </label>

              {touched.email && errors.email ? (
                <FormError error={errors.email} errorClass="field-error" />
              ) : null}
            </div>
            <CustomerNote isBottom={true} />
          </div>

          <div className="flex-center submit__container">
            {closed && (
              <button className="cross" type="button" onClick={onClick}>
                {closedText}
              </button>
            )}
            <button
              type="submit"
              className="btn btn-primary"
              value="submit"
              disabled={!dirty || !isValid}
            >
              {submitText}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RequestForm;
